import pubsub from '../utils/pubsub';
import screenUtil from '../utils/screen';
import {getValueFromThreshold, isValidThresholdValue} from '../utils/threshold-util';
import Slot from '../domain/Slot';
import {isConditionActive} from '../utils/condition-util';
import {getCurrentBreakpoint} from './breakpoints';
import {getValueForField as getValueForFieldFromCondConfig} from './conditionalService';
import {applyChange, Change} from '../utils/object-change-util';
import {getValueForBreakpoint} from '../utils/breakpoint-util';

const DEFAULT_THRESHOLD = 300,
	DEFAULT_DELAY = 30000,
	FIELD_TYPES = {
		'BREAKPOINT': 'breakpoint'
	};

export enum RefreshMode {
	View = 'view',
	Time = 'time',
}

type WaitForTimer = {
	slot: Slot
	timer: ReturnType<typeof setTimeout>
	delay: number
	startTime: number
	refreshSettings: RefreshSettings
};

let slotsToRefresh: Array<{ slot: Slot, refreshSettings: RefreshSettings }>,
	waitForInView: Array<{ slot: Slot, refreshSettings: RefreshSettings }>,
	waitForOutOfView: Array<{ slot: Slot, refreshSettings: RefreshSettings }>,
	waitForFirstTimeInView: Array<{ slot: Slot, refreshSettings: RefreshSettings }>,
	waitForTimer: WaitForTimer[];

export function init() {
	waitForInView = [];
	waitForOutOfView = [];
	waitForFirstTimeInView = [];
	waitForTimer = [];
	slotsToRefresh = [];

	pubsub.subscribe('slot.rendered', _onSlotRendered);
	pubsub.subscribe('scroll', _onScroll);
	document.addEventListener('visibilitychange', _onVisibilityChanged, {passive: true});
}

function _onSlotRendered(slot: Slot) {
	const refreshSettings = _applyRefreshConditions(slot);

	if (!getValueForBreakpoint(refreshSettings?.enabled)) {
		return;
	}

	if (screenUtil.isInView(slot.node, 0)) {
		_handleRefresh(slot, refreshSettings);
	} else {
		waitForFirstTimeInView.push({
			slot,
			refreshSettings
		});
	}
}

function _applyRefreshConditions(slot: Slot): RefreshSettings {
	let refreshSettings = slot.refreshSettings;

	const activeConditionalSettings = (refreshSettings?.conditionalSettings ?? []).filter((conditionalSetting, index) => {
		return conditionalSetting.conditions.some((conditionGroup) => conditionGroup.every(({
																								field,
																								...condition
																							}) => {
			try {
				return isConditionActive(condition as Condition, _getValueForField(field, slot as unknown as AnyObject));
			} catch (e) {
				console.error('[ADVERT] Something went wrong calculating refresh conditional settings:', e);
				refreshSettings.conditionalSettings.splice(index, 1);

				return false;
			}
		}));
	});

	activeConditionalSettings.forEach((conCfg: RefreshConditional) => refreshSettings = _applyConditional(conCfg, refreshSettings));

	return refreshSettings;
}

function _getValueForField(field: string | string[], slot: AnyObject) {
	if (Array.isArray(field)) {
		return field.reduce((obj: AnyObject, f) => obj?.[f], {slot});
	}

	if (field === FIELD_TYPES.BREAKPOINT) {
		return getCurrentBreakpoint();
	}

	try {
		return getValueForFieldFromCondConfig(field);
	} catch (e) {
		console.error('[ADVERT] Something went wrong calculating refresh conditional configurations:', e);

		return false;
	}
}

function _applyConditional(conditional: RefreshConditional, refreshSettings: RefreshSettings): RefreshSettings {
	conditional.settings.forEach((change) => applyChange(refreshSettings, change as Change));

	return refreshSettings;
}

function _handleRefresh(slot: Slot, refreshSettings: RefreshSettings) {
	// It's already being refreshed
	if (slotsToRefresh.some(({slot: slotToRefresh}) => slotToRefresh === slot)) {
		return;
	}

	if (_isViewModeRefresh(refreshSettings)) {
		waitForOutOfView.push({
			slot,
			refreshSettings
		});
	}

	if (_isTimeModeRefresh(refreshSettings)) {
		const delay = _getDelay(refreshSettings);

		waitForTimer.push({
			slot,
			delay,
			startTime: Date.now(),
			timer: null, // Might not start due to being out of view/screen for now
			refreshSettings
		});

		_updateTimers();
	}

	slotsToRefresh.push({
		slot,
		refreshSettings
	});
}

function _onScroll() {
	waitForOutOfView = waitForOutOfView.filter(({
													slot,
													refreshSettings
												}) => {
		if (!screenUtil.isInView(slot.node, _getThreshold(refreshSettings))) {
			waitForInView.push({
				slot,
				refreshSettings
			});

			return false;
		}

		return true;
	});

	waitForInView = waitForInView.filter(({
											  slot,
											  refreshSettings
										  }) => {
		if (screenUtil.isInView(slot.node, _getThreshold(refreshSettings))) {
			if (refreshSettings.minViewableThreshold) {
				_createMinViewableObserver(slot, refreshSettings.minViewableThreshold, RefreshMode.View);
			} else {
				_doRefresh(slot, RefreshMode.View);
			}

			return false;
		}

		return true;
	});

	waitForFirstTimeInView = waitForFirstTimeInView.filter(({
																slot,
																refreshSettings
															}) => {
		if (screenUtil.isInView(slot.node, 0)) {
			_handleRefresh(slot, refreshSettings);

			return false;
		}

		return true;
	});

	_updateTimers();
}

function _doRefresh(slot: Slot, refreshMode: RefreshMode) {
	const idx = slotsToRefresh.findIndex(({slot: slotToRefresh}) => slotToRefresh === slot);

	if (idx === -1) {
		return;
	}

	// Clean up any timers and waiting slot
	slotsToRefresh.splice(idx, 1);

	waitForTimer = waitForTimer.filter(wft => {
		if (wft.slot === slot) {
			clearTimeout(wft.timer);

			return false;
		}

		return true;
	});

	if (slot.refreshSettings?.paused) {
		return;
	}

	if (slot.refreshSettings?.fixedHeight) {
		slot.node.style.minHeight = `${slot.node.clientHeight}px`;
	}

	slot.refresh(refreshMode);
}

function _updateTimers() {
	waitForTimer.forEach((wft) => {
		if (_canRunTimer(wft.slot)) {
			// Only resume stopped timers
			if (wft.timer == null) {
				wft.startTime = Date.now();
				wft.timer = setTimeout(() => {
					if (wft.refreshSettings.minViewableThreshold) {
						_createMinViewableObserver(wft.slot, wft.refreshSettings.minViewableThreshold, RefreshMode.Time);
					} else {
						_doRefresh(wft.slot, RefreshMode.Time);
					}
				}, wft.delay);
			}
		} else {
			if (wft.timer != null) {
				clearTimeout(wft.timer);
				wft.timer = null;
				wft.delay -= Date.now() - wft.startTime;
			}
		}
	});
}

function _createMinViewableObserver(slot: Slot, minViewableThreshold: string, refreshMode: RefreshMode) {
	const threshold = parseInt(minViewableThreshold?.slice(0, -1), 10);

	if (isNaN(threshold)) {
		console.error('[ADVERT] Invalid minViewableThreshold:', minViewableThreshold);

		return;
	}

	const observer = new IntersectionObserver((entries) => {
		entries.forEach(entry => {
			if (entry.intersectionRatio >= threshold / 100) {
				_doRefresh(slot, refreshMode);

				observer.disconnect();
			}
		});
	}, {
		threshold: Array.from(Array(101).keys(), i => i / 100) // Create thresholds from 0 to 1 in increments of 0.01
	});

	observer.observe(slot.node);
}

function _canRunTimer(slot: Slot) {
	return document.visibilityState === 'visible' && screenUtil.isInView(slot.node, 0);
}

function _getThreshold(refreshSettings: RefreshSettings) {
	const threshold = refreshSettings.viewSettings?.threshold ?? DEFAULT_THRESHOLD,
		parsed = getValueFromThreshold(threshold);

	if (!isValidThresholdValue(parsed)) {
		return DEFAULT_THRESHOLD;
	}

	return parsed;
}

function _getDelay(refreshSettings: RefreshSettings): number {
	const delay = refreshSettings.timeSettings?.delay ?? DEFAULT_DELAY,
		parsed = parseInt(delay as unknown as string, 10);

	if (typeof parsed !== 'number' || isNaN(parsed)) {
		return DEFAULT_DELAY;
	}

	return parsed;
}

function _isViewModeRefresh(refreshSettings: RefreshSettings) {
	return refreshSettings.modes?.includes(RefreshMode.View) ?? true;
}

function _isTimeModeRefresh(refreshSettings: RefreshSettings) {
	return refreshSettings.modes?.includes(RefreshMode.Time) ?? false;
}

function _onVisibilityChanged() {
	_updateTimers();
}

